import MemoryStorage from 'memorystorage'

export class CustomWebStorageStore {
    constructor() {
        this._storage = new MemoryStorage('my-app');
    }

    getItem(key) {
        return this._storage.getItem(key);
    }

    setItem(key, value) {
        this._storage.setItem(key, value);
    }

    removeItem(key) {
        this._storage.removeItem(key);
    }

    get length() {
        return this._storage.length();
    }

    key(index) {
        return this._storage.key(index);
    }
}